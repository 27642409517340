import Vue from "vue";
import Router from "vue-router";
Vue.use(Router);
import { generateRoutes } from "@/utils/generateRoutes.js"

// const originalPush = VueRouter.prototype.push
// const originalReplace = VueRouter.prototype.replace
// //push
// VueRouter.prototype.push = function push(location, onResolve, onReject) {
//     if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject)
//     return originalPush.call(this, location).catch(err => err)
// }
// //replace
// VueRouter.prototype.replace = function push(location, onResolve, onReject) {
//     if (onResolve || onReject) return originalReplace.call(this, location, onResolve, onReject)
//     return originalReplace.call(this, location).catch(err => err)
// }

const Login = () => import('./View/Login.vue')
const NotFound = () => import('./View/NotFound.vue')
const Welcome = () => import('./View/Welcome.vue')
const Index = () => import('./View/Index.vue')

const defaultRoutes = [
    {
        path: "/login",
        name: "login",
        component: Login
    },
    {
        path: '/404',
        name: '404',
        component: NotFound
    },
    {
        path: "/",
        name: 'root',
        title: '首页',
        redirect: '/index',
        component: Index,
        children: [
            {
                path: "index",
                name: "index",
                component: Welcome
            },
        ]
    }
]

// let router =  new Router({
//     mode: 'history',
//     routes: defaultRoutes
// })

const createRouter = () => new Router({
    mode: 'history',
    routes: defaultRoutes
})
const router = createRouter()

router.beforeEach((to, from, next) => {
    const token = localStorage.getItem("token")
    if (to.path !== '/login' && !token) {
        next({
            path: '/login',
            replace: true
        })
    } else {
        if (to.path === '/login' && token) {
            next({ name: '/index', replace: true })
        } else {
            next()
        }
    }
})

export function resetRouter() {
    const newRouter = createRouter()
    router.matcher = newRouter.matcher // the relevant part
}

router.onReady(() => {
    //console.group('%c%s', 'color:red', `${new Date().getTime()}  路由完成初始导航----------`)
    //console.log('添加前-所有活跃的路由记录列表', router.getRoutes())
    resetRouter()
    generateRoutes()
    // console.log('添加后-所有活跃的路由记录列表', router.getRoutes())
    // console.groupEnd()
})

export default router;
