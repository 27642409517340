import router from "@/router";
import jwt_decode from "jwt-decode";
import {get} from "@/api/http";

export function generateTree(menuTree) {
    let routeList = []
    for (let item of menuTree) {
        let {id, pid, path, name, redirect, component, icon, index, title, contextmenu, children} = item
        let route = {id, pid, path, name, redirect, icon, index, title, contextmenu}
        route.component = () => import(`@/View/${component}.vue`)
        if (children) {
            route.children = generateTree(children)
        }
        routeList.push(route)
    }
    return routeList
}

export function generateRoutes() {
    let token = localStorage.getItem('token')
    if (!token) {
        return
    }

    let tokenData = jwt_decode(token)
    get("/api/v1/menu", {user_id: tokenData.id})
        .then((resp)=>{
            let routers = generateTree(resp.data)
            routers.forEach((route)=>{
                router.addRoute(route)
            })

            router.addRoute({
                path: '*',
                redirect: '/404'
            })
            router.replace("/index").catch(e=>e)
        })
        .catch((e)=>{
            return e
        })
}

export default {}
