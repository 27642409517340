import Vue from 'vue'
import App from './App.vue'
import ViewUI from 'view-design';
import 'view-design/dist/styles/iview.css';
import router from "@/router";
import * as Echarts from 'echarts';

import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
Vue.use(ElementUI)

import 'xe-utils'
import VXETable from 'vxe-table'
import 'vxe-table/lib/style.css'
Vue.use(VXETable)

import moment from "moment";
import _ from "lodash";

Vue.prototype.$echarts = Echarts;
Vue.prototype.$_ = _;
Vue.prototype.$moment = moment;
Vue.config.productionTip = false;
Vue.use(ViewUI);


console.log("当前环境变量: "+process.env.NODE_ENV)
const geoJSON = {
  'china': require('./assets/china.json')
};
Echarts.registerMap('china', geoJSON['china']);

new Vue({
  render: h => h(App),
  router,
}).$mount('#app')