import axios from 'axios';
import router from "@/router";
import QS from 'qs';
import Notice from "@/components/baseNotice.js"

// 环境的切换
if (process.env.NODE_ENV === 'development') {
    // axios.defaults.baseURL = 'http://127.0.0.1:8089';
} else if (process.env.NODE_ENV === 'testing') {
    axios.defaults.baseURL = 'http://ipaas.test.paigod.work';
} else if (process.env.NODE_ENV === 'production') {
    axios.defaults.baseURL = 'https://ipaas.paigod.work/';
}


// axios.defaults.baseURL = 'http://ipaas.test.paigod.work'


axios.defaults.timeout = 10000;
axios.defaults.headers.post['Content-Type'] = 'application/json;charset=UTF-8';
axios.defaults.headers.patch['Content-Type'] = 'application/json;charset=UTF-8';
axios.defaults.headers.put['Content-Type'] = 'application/json;charset=UTF-8';

// 响应拦截器
axios.interceptors.response.use(
    response => {
        // 如果返回的状态码为200，说明接口请求成功，可以正常拿到数据
        // 否则的话抛出错误
        if (response.status === 200) {
            return Promise.resolve(response);
        } else {
            return Promise.reject(response);
        }
    },
    // 服务器状态码不是2开头的的情况
    // 这里可以跟你们的后台开发人员协商好统一的错误状态码
    // 然后根据返回的状态码进行一些操作，例如登录过期提示，错误提示等等
    // 下面列举几个常见的操作，其他需求可自行扩展
    error => {
        console.log(error)
        switch (error.response.status) {
            // 401: 未登录
            // 未登录则跳转登录页面，并携带当前页面的路径
            // 在登录成功后返回当前页面，这一步需要在登录页操作。
            case 401:
                Notice.error('权限认证', error.response.data.msg);
                break;

            // 403 token过期
            // 登录过期对用户进行提示
            // 清除本地token和清空vuex中token对象
            // 跳转登录页面
            case 403:
                // Notice.error('登录过期', error.response.data.msg);
                // 清除token
                localStorage.removeItem("token")
                router.replace({ "path": '/login' }).then(r => console.log(r))
                // 跳转登录页面，并将要浏览的页面fullPath传过去，登录成功后跳转需要访问的页面
                // setTimeout(() => {
                //     router.replace({
                //         path: '/login',
                //         request: {
                //             redirect: router.currentRoute.fullPath
                //         }
                //     })
                // }, 1000);
                break;

            // 404请求不存在
            case 404:
                Notice.error('网络请求', '网络请求不存在');
                break;
            // 其他错误，直接抛出错误提示
            default:
                Notice.error('请求错误', error.response.data.msg);
        }
        return Promise.reject(error.response);
    }
);

axios.interceptors.request.use(
    config => {
        const token = localStorage.getItem('token');
        if (token) { // 判断是否存在token，如果存在的话，则每个http header都加上token
            config.headers.token = token;
        }

        return config;
    },
    err => {
        return Promise.reject(err);
    });

export function get(url, params) {
    return new Promise((resolve, reject) => {
        axios.get(url, {
            params: params,
        }).then(res => {
            resolve(res.data);
        }).catch(err => {
            reject(err.data)
        })
    })
}


export function patch(url, params) {
    return new Promise((resolve, reject) => {
        axios.patch(url, {
            params: params,
        }).then(res => {
            resolve(res.data);
        }).catch(err => {
            reject(err.data)
        })
    })
}

export function post(url, params, json = true) {
    return new Promise((resolve, reject) => {
        axios.post(url, json ? JSON.stringify(params) : QS.stringify(params))
            .then(res => {
                console.log(res)
                resolve(res.data);
            })
            .catch(err => {
                console.log(err)
                reject(err.data)
            })
    });
}

export function put(url, params, json = true) {
    return new Promise((resolve, reject) => {
        axios.put(url, json ? JSON.stringify(params) : QS.stringify(params))
            .then(res => {
                resolve(res.data);
            })
            .catch(err => {
                reject(err.data)
            })
    });
}

export function del(url, params, json = true) {
    return new Promise((resolve, reject) => {
        axios.delete(url, {
            data: json ? JSON.stringify(params) : QS.stringify(params)
        })
            .then(res => {
                resolve(res.data);
            })
            .catch(err => {
                reject(err.data)
            })
            .then(r => r)
    });
}


export function get_snmp(url, params) {
    return new Promise((resolve, reject) => {
        axios.get(url, {
            headers: {
                token: localStorage.getItem("token"),
                account: localStorage.getItem("account")
            },
            params: params
        }).then(res => {
            resolve(res.data);
        }).catch(err => {
            reject(err.data)
        })
    })
}

export function post_snmp(url, params, json = true) {
    return new Promise((resolve, reject) => {
        axios.post(url, json ? JSON.stringify(params) : QS.stringify(params), {
            headers: {
                token: localStorage.getItem("token"),
                account: localStorage.getItem("account")
            }
        })
            .then(res => {
                resolve(res.data);
            })
            .catch(err => {
                reject(err.data)
            })
            .then(r => r)
    });
}


export function put_snmp(url, params, json = true) {
    return new Promise((resolve, reject) => {
        axios.put(url, json ? JSON.stringify(params) : QS.stringify(params), {
            headers: {
                token: localStorage.getItem("token"),
                account: localStorage.getItem("account")
            }
        })
            .then(res => {
                resolve(res.data);
            })
            .catch(err => {
                reject(err.data)
            })
            .then(r => r)
    });
}


export function delete_snmp(url) {
    return new Promise((resolve, reject) => {
        axios.delete(url)
            .then(res => {
                resolve(res.data);
            })
            .catch(err => {
                reject(err.data)
            })
    });
}

export function export_snmp(url, params) {
    return new Promise((resolve, reject) => {
        axios.get(url, {
            responseType: 'blob',
            headers: {
                token: localStorage.getItem("token"),
                account: localStorage.getItem("account")
            },
            params: params
        }).then(res => {
            resolve(res.data);
        }).catch(err => {
            reject(err.data)
        })
    })
}

export function getAlertAPIUrl(path) {
    return `/monitor${path}`;
    //return `http://localhost:19090${path}`;
}

export function ckquery(sql) {
    return new Promise((resolve, reject) => {
        axios.post("/api/v1/ckquery", sql, {
            headers: {
                ContentType: "text/plain"
            }
        })
            .then(res => {
                resolve(res.data);
            })
            .catch(err => {
                reject(err.data)
            })
            .then(r => r)
    });
}