import {Notice} from "view-design";
export default {
    info(title, desc) {
        Notice.info({
            title: title,
            desc: desc
        })
    },
    success(title, desc) {
        Notice.success({
            title: title,
            desc: desc
        })
    },
    warning(title, desc) {
        Notice.warning({
            title: title,
            desc: desc
        })
    },
    error(title, desc) {
        Notice.error({
            title: title,
            desc: desc
        })
    }
}